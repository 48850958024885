<template>
  <v-row style="margin:1em">
    <v-col :cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
      <v-card>
        <v-card-title>GreatScots Campus Directory</v-card-title>
        <v-row>
          <v-col>
            <v-list elevation="2" class="ma-3">
              <v-subheader>Faculty/Staff Directory</v-subheader>
              <v-list-item to="/greatscots/list/DepartmentalDirectory">
                <v-list-item-title>Faculty/Staff Departmental Directory</v-list-item-title>
              </v-list-item>
              <v-list-item to="/greatscots/list/FacultyStaffDirectory">
                <v-list-item-title>Faculty/Staff Alphabetic Directory</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col v-if="user.roles.filter((role) => role === 'Employee').length > 0">
            <v-list elevation="2" class="ma-3">
              <v-subheader>Class Lists</v-subheader>
              <v-list-item to="/greatscots/list/Year:SR">
                <v-list-item-title>Senior Class List</v-list-item-title>
              </v-list-item>
              <v-list-item to="/greatscots/list/Year:JR">
                <v-list-item-title>Junior Class List</v-list-item-title>
              </v-list-item>
              <v-list-item to="/greatscots/list/Year:SO">
                <v-list-item-title>Sophomore Class List</v-list-item-title>
              </v-list-item>
              <v-list-item to="/greatscots/list/Year:FR">
                <v-list-item-title>Freshman Class List</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col :cols="12" md="6" lg="4" xl="3" v-for="item in lists" :key="item.title">
      <v-card>
        <v-card-title>{{ item.title }}</v-card-title>
        <template v-if="Array.isArray(item.items)">
          <v-list>
            <v-list-item v-for="list in item.items" :key="list._id" :to="'/greatscots/list/' + list._id">
              <v-list-item-title>{{ list.title }}</v-list-item-title>
              <v-list-item-action v-if="isEditor(list)">
                <v-btn icon :to="'/greatscots/edit/' + list._id">
                  <v-icon small>fal fa-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
        </v-list>
        </template>
        <v-expansion-panels v-else>
          <v-expansion-panel v-for="(items, group) in item.items" :key="group" focusable>
            <v-expansion-panel-header>{{ getLabel(group) }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item v-for="listItem in items" :key="listItem._id" :to="'/greatscots/class/' + listItem._id">
                  <v-list-item-content v-if="item.title === 'My Classes'">
                    <v-list-item-title>{{ listItem.title.split(' - ')[0] }}</v-list-item-title>
                    <v-list-item-subtitle>{{ listItem.title.split(' - ')[1] }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-title v-else>{{ listItem.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const athleticLists = ref([])
    const classLists = ref([])
    const workerLists = ref([])
    const myLists = ref([])
    const sharedLists = ref([])

    const lists = computed(() => {
      let arr = []
      let i = 0
      for (let term in classLists.value) if (term) i++
      if (i > 0) arr.push({ title: 'My Classes', items: classLists.value, group: 'Term' })
      if (workerLists.value.length > 0) arr.push({ title: 'Work Study Students', items: workerLists.value })
      if (myLists.value.length > 0) arr.push({ title: 'My Lists', items: myLists.value })
      if (sharedLists.value.length > 0) arr.push({ title: 'Shared with Me', items: sharedLists.value })
      // if (athleticLists.value.length > 0) arr.push({ title: 'Athletic Teams', items: athleticLists.value })
      return arr
    })

    const getLabel = (group) => {
      if (!isNaN(parseInt(group)) && group.length === 6) {
        const year = group.substr(0, 4)
        const termSuffix = group.substr(4, 2)
        switch (termSuffix) {
          case '05': return 'Spring ' + year
          case '06': return 'MAT Spring ' + year
          case '10': return 'May Term ' + year
          case '12': return 'Spring ' + year + ' Dual Enrollment'
          case '55': return 'M.Ed. Summer ' + year
          case '56': return 'MAT Summer ' + year
          case '57': return 'Summer Institute ' + year
          case '58': return 'MAT Fall ' + year
          case '60': return 'Fall ' + year
        }
      }
      return group
    }

    onMounted(() => {
      let { pidm, directoryId } = user.value
      // Load classes from the calendar/classes service
      let dt = new Date()
      dt.setDate(dt.getDate() - 30)
      // Get all terms whose end date is greater than 30 days ago; this will keep a term list available through 30 days after the term has ended
      root.$feathers.service('system/term').find({ query: { end: { $gt: dt } } }).then(({ data }) => {
        let terms = []
        data.forEach(({ term }) => { terms.push(term) })
        let coursesByTerm = {}
        root.$feathers.service('calendar/classes').find({ query: { term: { $in: terms }, $or: [ { 'instructors.pidm': pidm }, { 'students.pidm': pidm } ], $limit: 50 } }).then(({ data }) => {
          data.forEach(({ term, title, _id }) => {
            if (!(term in coursesByTerm)) coursesByTerm[term] = []
            coursesByTerm[term].push({ title, _id })
          })
          classLists.value = coursesByTerm
        })
      })
      // Load sports from the directory/list service
      // root.$feathers.service('directory/list').find({ query: { type: 'sport', $limit: 50, $sort: { title: 0 } } }).then(({ data }) => {
      //   athleticLists.value = data
      // })
      // Load work-study from the directory/list service
      root.$feathers.service('directory/list').find({ query: { type: 'workstudy', $limit: 50, $sort: { title: 0 } } }).then(({ data }) => {
        workerLists.value = data
      })
      // Load owned lists from the directory/list service
      root.$feathers.service('directory/list').find({ query: { type: 'list', share: { $elemMatch: { user: directoryId, role: 'owner' } }, $limit: 50, $sort: { title: 0 } } }).then(({ data }) => {
        myLists.value = data
      })
      // Load shared lists from the directory/list service
      root.$feathers.service('directory/list').find({ query: { type: 'list', share: { $elemMatch: { user: directoryId, role: { $ne: 'owner' } } }, $limit: 50, $sort: { title: 0 } } }).then(({ data }) => {
        sharedLists.value = data
      })
    })

    function isEditor ({ owner, share }) {
      const directoryId = JSON.stringify(user.value.directoryId)
      // If they are the owner of the list then they can edit the list
      if (JSON.stringify(owner) === directoryId) return true
      // If they are either shared as an admin or editor then they can edit the list
      const temp = share.filter(({ user, role }) => JSON.stringify(user) === directoryId && ['admin', 'editor'].includes(role))
      return temp > 0
    }

    return {
      user,
      athleticLists,
      classLists,
      workerLists,
      myLists,
      sharedLists,
      lists,
      getLabel,
      isEditor
    }
  }
}
</script>
